import { Keys } from './Types'
import uuid from 'uuid'

export function setToken(data) {
  if (data) data = JSON.stringify(data)
  localStorage.setItem(Keys.STORAGE.AUTH_RESPONSE, data)
}

export function getToken() {
  let data = localStorage.getItem(Keys.STORAGE.AUTH_RESPONSE)
  if (data) data = JSON.parse(data)
  return data
}

export function setUserId(data) {
  localStorage.setItem(Keys.STORAGE.USER_KEY, data)
}

export function getUserId() {
  const data = localStorage.getItem(Keys.STORAGE.USER_KEY)
  if (data) return data

  const id = uuid()
  setUserId(id)
  return id
}

export function removeUserId() {
  return localStorage.removeItem(Keys.STORAGE.USER_KEY)
}

export function removeToken() {
  return localStorage.removeItem(Keys.STORAGE.AUTH_RESPONSE)
}

export function setLng(data) {
  localStorage.setItem(Keys.STORAGE.LANGUAGE, data)
}

export function getLng() {
  return localStorage.getItem(Keys.STORAGE.LANGUAGE)
}

export function setProv(data) {
  localStorage.setItem(Keys.STORAGE.PROVIDER, data)
}

export function getProv() {
  return localStorage.getItem(Keys.STORAGE.PROVIDER)
}
