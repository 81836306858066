import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import ChatBotWidget from './ChatBotWidget'

import { I18nextProvider } from 'react-i18next'
import i18n from '../../locale/i18n'
import { StylesProvider, jssPreset } from '@material-ui/styles'
import { create } from 'jss'
import rtl from 'jss-rtl'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

class App extends Component {
  render() {
    const theme = createMuiTheme({
      direction: i18n.dir(i18n.language)
    })

    return (
      <I18nextProvider i18n={i18n}>
        <StylesProvider jss={jss}>
          <MuiThemeProvider theme={theme}>
            <div dir={i18n.dir(i18n.language)}>
              <ChatBotWidget />
            </div>
          </MuiThemeProvider>
        </StylesProvider>
      </I18nextProvider>
    )
  }
}

App.propTypes = {
  i18n: PropTypes.shape({
    language: PropTypes.string,
    dir: PropTypes.func
  })
}

export default withTranslation('translations')(App)
