import config from '../config'
import { Keys } from './Types'
import { getUserId, getLng, getProv } from './Storage'
const getBaseUrl = () => {
  return config.api_base_url
}

const baseDeviceRequest = async (method, path, data = {}) => {
  console.info(`API> ${method} ${path}`)
  return timeout(
    60000,
    fetch(`${getBaseUrl()}/api/v1${path}`, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: method === 'get' ? null : JSON.stringify(data)
    })
      .catch(handleNetWorkError) // handle network issues
      .then(checkStatus)
      .then(parseJSON),
    path
  )
}

const checkStatus = async response => {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  switch (response.status) {
    case 404:
      return Promise.reject(new Error(Keys.ERRORS.NOT_FOUND_ERROR))
    case 401:
      return Promise.reject(new Error(Keys.ERRORS.UNAUTHORIZED_ERROR))
    case 403:
      return Promise.reject(new Error(Keys.ERRORS.FORBIDDEN_ERROR))
    default:
      return Promise.reject(await response.json())
  }
}

const parseJSON = response => {
  if (response.status === 204 || response.status === 205) {
    return null
  }
  return response.text().then(text => {
    try {
      return JSON.parse(text.trim())
    } catch (error) {
      return text
    }
  })
}

const handleNetWorkError = error => {
  throw new Error(Keys.ERRORS.NETWORK_ERROR)
}

function timeout(ms, promise) {
  return new Promise(function(resolve, reject) {
    const timeOut = setTimeout(function() {
      reject(new Error(Keys.ERRORS.TIME_OUT_ERROR))
    }, ms)
    promise.then(resolve, reject).finally(() => {
      clearTimeout(timeOut)
    })
  })
}

const post = (path, data) => baseDeviceRequest('POST', path, data)

export const detectIntent = async message => {
  const sessionId = getUserId()
  const queryInput = {
    sessionId: sessionId,
    languageCode: getLng() ? getLng() : 'fr',
    text: message,
    provider: getProv() ? getProv() : 'rasa'
  }
  return post('/chatbots/webhook', queryInput)
}

export const register = user => {
  return post('/customers', user)
}
