import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import styles from './styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}))

CheckboxesGroup.propTypes = {
  title: PropTypes.string,
  submit: PropTypes.string,
  onSend: PropTypes.func,
  quickReplies: PropTypes.array,
  defaultValue: PropTypes.string
}

export default function CheckboxesGroup(props) {
  const { quickReplies, title, defaultValue } = props

  const classes = useStyles()
  const [checked, setChecked] = React.useState([quickReplies.length - 1]) // here we assume that defaultValue is the last element of values
  const [disabled, setDisabled] = React.useState(false)

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)

    let newChecked = [...checked]

    if (currentIndex === -1) {
      if (value === quickReplies.length - 1) {
        newChecked = [value]
      } else {
        newChecked.push(value)
        newChecked.indexOf(quickReplies.length - 1) !== -1 &&
          newChecked.splice(newChecked.indexOf(quickReplies.length - 1), 1)
      }
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  return (
    <List disabled={disabled} className={classes.root}>
      {quickReplies.map((quickReply, index) => {
        const labelId = `checkbox-list-label-${quickReply.value}`
        return (
          <ListItem
            key={quickReply.value}
            role={undefined}
            dense
            button
            onClick={handleToggle(index)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(index) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={quickReply.label} />
          </ListItem>
        )
      })}
      <Button
        style={styles.quickReply}
        variant="outlined"
        color="primary"
        disabled={disabled}
        onClick={() => {
          setDisabled(true)
          if (checked.length < 1) {
            props.onSend(defaultValue)
          } else {
            const checkedValues = checked.reduce((accumulator, cur) => {
              return `${accumulator}${quickReplies[cur].value},`
            }, '')
            props.onSend(checkedValues)
          }
        }}
      >
        {props.submit ? props.submit : 'Envoyer'}
      </Button>
    </List>
  )
}
