import React, { Component } from 'react'
import {
  Widget,
  setQuickButtons,
  addUserMessage,
  addResponseMessage,
  toggleInputDisabled,
  toggleMsgLoader,
  renderCustomComponent,
  isWidgetOpened,
  toggleWidget,
  dropMessages
} from 'react-chat-widget'
import 'react-chat-widget/lib/styles.css'
import './styles.css'
import { detectIntent } from '../../utils/Api'
import bot from '../../images/bot.jpeg'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import MediaCard from './MediaCard'
import config from '../../config'
import CheckBoxReplies from './CheckBoxReplies'
import RadioQuickReplies from './RadioQuickReplies'
import { Keys } from '../../utils/Types'
import { setLng, getLng } from '../../utils/Storage'
import Markdown from './Markdown'

class ChatBotWidget extends Component {
  constructor(props) {
    super(props)

    let lng = this.getQueryVariable('lng')
    let fullScreenMode = this.getQueryVariable('fullScreenMode')

    fullScreenMode =
      fullScreenMode === 'true'
        ? true
        : this.props.fullScreenMode === true
        ? true
        : null
    const i18n = this.props.i18n
    if (lng && i18n.language !== lng) {
      setLng(lng)
      i18n.changeLanguage(lng)
    }

    this.state = {
      fallback: 0,
      lastMessage: '',
      lastResponse: '',
      intent: null,
      lng: lng ? lng : i18n.language,
      fullScreenMode
    }
  }

  componentDidMount() {
    const lng = getLng() ? getLng() : 'fr'
    this.initWidget(lng)
    this.props.i18n.on('languageChanged', this.initWidget.bind(this))
  }

  initWidget(lng) {
    dropMessages()
    this.setState({ lng })
    if (this.state.fullScreenMode || isWidgetOpened()) toggleWidget()
    this.addMenuButtons()
  }

  addMenuButtons() {
    setQuickButtons([
      { label: this.props.t('audit'), value: this.props.t('diagnose') },
      { label: this.props.t('feedback'), value: this.props.t('time_out') },
      { label: 'X', value: 'clear-command' }
    ])
  }

  getCustomLauncher(handleToggle) {
    // The return will be your own Launcher component
    return (
      <button onClick={handleToggle}>This is my launcher component!</button>
    )
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1])
      }
    }
    return null
  }

  renderCutomResponse(message) {
    renderCustomComponent(() => (
      <div
        className="rows"
        dir={this.state.lng === 'ar' ? 'rtl' : 'ltr'}
        key={`${message.substring(0, 4)}`}
      >
        <div className="row">
          {<img src={bot} className="rcw-avatar" alt="profile" />}
        </div>
        <div className="row">
          <Markdown
            style={{
              backgroundColor: '#f4f7f9',
              padding: 10,
              marginRight: 5,
              borderRadius: 10
            }}
            key={message.substring(0, 40)}
          >
            {message}
          </Markdown>
        </div>
      </div>
    ))
  }
  renderCardResponse(cards) {
    if (cards && cards.length > 0) {
      cards.forEach(card => {
        renderCustomComponent(() => (
          <MediaCard
            title={card.title}
            content={card.subtitle}
            imageUrl={card.imageUri}
            buttons={card.buttons}
          />
        ))
      })
    }
  }

  handleQuickButtonClicked(data) {
    switch (data) {
      case 'fullScreenMode':
        this.setState({ fullScreenMode: true })
        break

      case 'clear-command':
        dropMessages()
        this.addMenuButtons()
        break

      case this.props.t('diagnose'):
        addUserMessage(this.props.t('diagnostic'))
        this.handleNewUserMessage(data)
        break

      case this.props.t('time_out'):
        addUserMessage(this.props.t('feedback'))
        this.handleNewUserMessage(data)
        break

      default:
        addUserMessage(data)
        break
    }
  }

  extractTerm(searchTerm, value) {
    let prefix = null
    if (value.includes(searchTerm)) {
      const indexOfFirst = value.indexOf(searchTerm)

      if (indexOfFirst !== -1) {
        prefix =
          ' ' + value.substr(indexOfFirst + searchTerm.length, value.length)
      }
    }

    return prefix
  }

  addTextResponse(message) {
    if (message) {
      if (this.state.lng === 'ar') {
        this.renderCutomResponse(message)
      } else {
        addResponseMessage(message)
      }
    }
  }

  handleNewUserMessage(newMessage) {
    // Now send the message throught the backend API
    if (newMessage !== this.props.t('diagnose')) {
      let suffix = this.state.suffix

      if (suffix) {
        if (this.state.responseType === 'NUMBER' && isNaN(newMessage)) {
          alert('you should put number')
          this.state.lastResponse &&
            this.addTextResponse(this.state.lastResponse)
          return
        } else {
          newMessage = `${suffix} ${newMessage}`
        }
      }
    }
    this.setState({ lastMessage: newMessage })
    detectIntent(newMessage)
      .then(this.handleResponse.bind(this))
      .catch(this.handleError.bind(this))

    toggleInputDisabled()
    toggleMsgLoader()
  }

  handleError(e) {
    toggleMsgLoader()
    toggleInputDisabled()

    if (
      e.message === Keys.ERRORS.NETWORK_ERROR ||
      e.message === Keys.ERRORS.TIME_OUT_ERROR
    ) {
      this.addTextResponse(this.props.t('network_error'))
    } else {
      this.addTextResponse(e.message || this.props.t('error'))
    }
  }

  renderTextResponse(texts) {
    if (Array.isArray(texts)) {
      texts &&
        texts.forEach(message => {
          const urls = message.match(/\bhttps?:\/\/\S+/gi)
          if (urls && Array.isArray(urls)) {
            urls.forEach(url => {
              message = message.replace(url, `[${url}](${url})`)
            })
          }
          // isue with reat-chat-widget package
          message = message.split('\n').join('\n\n')
          this.addTextResponse(message)
          this.setState({ lastResponse: message })
        })
    } else {
      this.addTextResponse('Empty response')
    }
  }

  renderQuickReplies(quickReplies) {
    const { type, title, values } = quickReplies

    const quickButtons = values.map(value => {
      return { label: value.title, value: value.id } // soit laisser comme ça soit put only value. i.e : quickButtons = values
    })
    if (type === 'checkbox') {
      this.renderCheckBoxReplies({
        title: title,
        checkboxes: quickButtons,
        defaultValue: quickReplies.default
      })
    } else {
      this.renderRadioQuickReplies({
        title: title,
        quickReplies: quickButtons,
        defaultValue: quickReplies.default
      })
    }
  }

  renderCheckBoxReplies({ title, checkboxes, defaultValue }) {
    title && this.addTextResponse(title)
    renderCustomComponent(() => (
      <CheckBoxReplies
        title={title}
        onSend={this.handleNewUserMessage.bind(this)}
        submit={this.props.t('submit')}
        quickReplies={checkboxes}
        defaultValue={defaultValue}
      />
    ))
  }

  renderRadioQuickReplies({ title, quickReplies, defaultValue }) {
    title && this.addTextResponse(title)
    renderCustomComponent(() => (
      <RadioQuickReplies
        fullScreen={this.state.fullScreenMode}
        title={title}
        onSend={this.handleNewUserMessage.bind(this)}
        quickReplies={quickReplies}
        defaultValue={defaultValue}
      />
    ))
  }

  handleResponse(data) {
    toggleMsgLoader()
    toggleInputDisabled()

    const { intent, platform, responseType, suffix } = data

    this.setState({ intent, platform, responseType, suffix })

    const { texts, quickReplies, cards } = data.messages

    if (texts) this.renderTextResponse(texts)
    if (quickReplies) this.renderQuickReplies(quickReplies)
    if (cards) this.renderCardResponse(cards)

    // if server do not understant the user ${config.dialogflow.fallback} times => ask server to guide the user
    if (data.intent && data.intent === config.dialogflow.fallback_intent) {
      this.setState({ fallback: this.state.fallback + 1 })
    } else {
      this.setState({ fallback: 0 })
    }

    if (this.state.fallback > config.dialogflow.fallback) {
      if (isWidgetOpened()) {
        this.handleNewUserMessage(this.props.t('fallback'))
        this.setState({ fallback: 0 })
      }
    }
  }

  render() {
    const { t } = this.props
    return (
      <Widget
        // launcher={handleToggle => this.getCustomLauncher(handleToggle)}
        showCloseButton={!this.state.fullScreenMode}
        fullScreenMode={this.state.fullScreenMode}
        title={t('Welcome')}
        subtitle={t('chat_subtitle')}
        profileAvatar={bot}
        senderPlaceHolder={t('placeholder')}
        handleNewUserMessage={this.handleNewUserMessage.bind(this)}
        handleQuickButtonClicked={this.handleQuickButtonClicked.bind(this)}
        autofocus={false}
      />
    )
  }
}

ChatBotWidget.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.any,
  fullScreenMode: PropTypes.bool
}
export default withTranslation('translations')(ChatBotWidget)
