require('dotenv').config()
const env = process.env.NODE_ENV || 'development'
const {
  REACT_APP_API_BASE_URL,
  REACT_APP_DEFAULT_PROVIDER,
  REACT_APP_SHOW_PROVIDER,
  REACT_APP_TIME_OUT,
  REACT_APP_FALLBACK,
  REACT_APP_FALLBACK_INTENT
} = process.env

const envConfig = {
  env,
  api_base_url: REACT_APP_API_BASE_URL,
  defaultProvider :  REACT_APP_DEFAULT_PROVIDER || 'rasa',
  showProvider : REACT_APP_SHOW_PROVIDER ,
  dialogflow: {
    time_out: REACT_APP_TIME_OUT || 60000,
    fallback: REACT_APP_FALLBACK || 2,
    fallback_intent: REACT_APP_FALLBACK_INTENT || 'Default Fallback Intent'
  }
}

console.log("envConfig=>",envConfig)

export default envConfig
