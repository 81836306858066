import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: {
    width: 345
  },
  media: {
    height: 140,
    resize: 'both'
  }
})

MediaCard.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  imageUrl: PropTypes.string,
  buttons: PropTypes.array
}

export default function MediaCard(props) {
  const classes = useStyles()

  const { title, imageUrl, buttons, content } = props

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={() => window.open(imageUrl)}>
        <CardMedia className={classes.media} image={imageUrl} />

        <CardContent>
          {title && (
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
          )}
          {content && (
            <Typography variant="body2" color="textSecondary" component="p">
              {content}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      {buttons && (
        <CardActions>
          {buttons.map((button, index) => (
            <Button
              key={`button${index}`}
              size="small"
              color="primary"
              href={button.postback}
              target="_blank"
            >
              {button.text}
            </Button>
          ))}
        </CardActions>
      )}
    </Card>
  )
}
