const TYPES = {
  STORAGE: {
    AUTH_RESPONSE: 'auth_response',
    USER_KEY: 'user_key',
    LANGUAGE: 'LANGUAGE',
    PROVIDER: 'PROVIDER'
  },
  ERRORS: {
    NETWORK_ERROR: 'NETWORK_ERROR',
    TIME_OUT_ERROR: 'TIME_OUT_ERROR',
    NOT_FOUND_ERROR: 'NOT_FOUND_ERROR',
    UNAUTHORIZED_ERROR: 'UNAUTHORIZED_ERROR',
    FORBIDDEN_ERROR: 'FORBIDDEN_ERROR'
  }
}

export const Keys = Object.freeze(TYPES)
