const translations = {
  fr: {
    translations: {
      Welcome: 'Bienvenue',
      'Sign Out': 'déconnecter',
      'Sign up': "s'enregistrer",
      'Forgot password?': 'Mot de passe oublié?',
      'You dont have an account? Sign up':
        "Vous n'avez pas de compte? S'inscrire",
      Password: 'Mot de passe ',
      'Email Address': 'Adresse électronique',
      'Last Name': 'Nom de famille',
      'First Name': 'Prénom',
      'Already have an account? Sign in':
        'Vous avez déjà un compte? se connecter',
      'SIGN IN': 'Se connecter',
      'SIGN UP': "S'inscrire",
      chat_subtitle:
        'Pour vous renseigner sur Proxylan, veuillez poser vos questions',
      Healthcare: 'Soins de santé',
      Articles: 'Articles',
      Health: 'Santé',
      'Continue reading…': 'Continue reading…',
      ar: 'Arabic',
      fr: 'Français',
      lng: 'عربي',
      language: 'Langue',
      provider: 'Provider',
      Social: 'Réseaux sociaux',
      placeholder: 'Posez votre question',
      time_out: 'temps écoulé',
      fallback: 'erreur message',
      submit: 'Soumettre',
      diagnose: 'Téléconsult formulaire',
      feedback: 'Feedback',
      diagnostic: 'Audit-Sécurité',
      audit: 'WebApp Audit',
      error: "L'application a rencontré une erreur inconnue",
      network_error: 'Erreur réseau, Veuillez vérifier votre connexion'
    }
  },
  ar: {
    translations: {
      Welcome: 'مرحبًا',
      'Sign Out': 'تسجيل الخروج',
      'Sign up': 'تسجيل الدخول',
      'Forgot password?': 'هل نسيت كلمة المرور؟',
      'ليس لديك حساب؟ سجل': "Vous n'avez pas de compte? S'inscrire",
      Password: 'كلمه السر',
      'Email Address': 'عنوان البريد الإلكتروني',
      'Last Name': 'الكنية',
      'First Name': 'الاسم الاول',
      'Already have an account? Sign in': 'هل لديك حساب؟ تسجيل الدخول',
      'SIGN UP': 'سجل',
      'SIGN IN': 'تسجيل الدخول',
      'You dont have an account? Sign up': 'ليس لديك حساب؟ سجل',
      chat_subtitle: 'كيف يمكنني المساعدة؟',
      Healthcare: 'رعاية صحية',
      Articles: 'مقالات',
      Health: 'الصحة',
      'Continue reading…': 'Continue reading…',
      ar: 'عربي',
      fr: 'فرنسي',
      language: 'اللغة',
      provider: 'مزود البيانات',
      Social: 'شبكات التواصل الاجتماعي',
      placeholder: 'أكتب هنا من فضلك',
      time_out: 'الوقت المنقضي',
      fallback: 'رسالة خطأ',
      submit: 'إرسال',
      diagnose: 'Téléconsult formulaire',
      feedback: 'ملاحظات',
      diagnostic: 'التدقيق الامني',
      audit: 'تدقيق امني',
      error: 'واجه التطبيق خطأ غير معروف',
      lng: 'Français',
      network_error: 'خطأ في الشبكة، يرجى التأكد من صِلتك بالإنترنت'
    }
  }
}

export default translations
