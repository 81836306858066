import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import styles from './styles'
const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 300,
    minWidth: 300,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  full: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}))

RadioQuickReplies.propTypes = {
  title: PropTypes.string,
  onSend: PropTypes.func,
  quickReplies: PropTypes.array,
  fullScreen: PropTypes.bool,
  defaultValue: PropTypes.string
}

export default function RadioQuickReplies(props) {
  const classes = useStyles()

  const { title, quickReplies, defaultValue } = props

  const [state, setState] = React.useState({
    title: title,
    quickReplies: quickReplies,
    disabled: false
  })

  return (
    <div className={props.fullScreen ? classes.full : classes.container}>
      {state.quickReplies &&
        state.quickReplies.map((quickReply, index) => {
          return (
            <Button
              style={styles.quickReply}
              key={`${quickReply.value}-${index}`}
              label={quickReply.label}
              disabled={state.disabled}
              color="primary"
              onClick={() => {
                props.onSend(quickReply.value)
                setState({ ...state, disabled: true })
              }}
              variant="outlined"
            >
              {quickReply.label}
            </Button>
          )
        })}
    </div>
  )
}
