import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
//import LanguageDetector from 'i18next-browser-languagedetector'
import translations from './translations'
import { getLng } from '../utils/Storage'

i18n
  .use(initReactI18next)
  //.use(LanguageDetector)
  .init({
    lng: getLng() ? getLng() : 'ar',
    // we init with resources
    resources: translations,
    fallbackLng: 'ar',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },

    react: {
      wait: true
    }
  })
export default i18n
