const styles = {
  launcher: {
    width: 60,
    height: 60,
    margin: 0,
    top: 'auto',
    right: 100,
    bottom: 20,
    left: 'auto',
    position: 'fixed'
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 300
  },
  quickReply: {
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    minWidth: 160,
    paddingVertical: 7,
    paddingHorizontal: 12,
    minHeight: 50,
    maxHeight: 100,
    borderRadius: 13,
    margin: 3,
    textTransform: 'none'
  }
}

export default styles
